/* import faker from 'faker'
import Box from '../components/Box/Box'
import GridBox from '../components/GridBox/GridBox'
import BoxFloatingVideo from '../components/BoxFloatingVideo/BoxFloatingVideo' */
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm/ContactForm'
import MediaBox from '../components/MediaBox/MediaBox'
/* import ParallaxMedia from '../components/ParallaxMedia/ParallaxMedia' */
import TextRow from '../components/TextRow/TextRow'
import text from '../lang/it/about.json'
import isMobile from '../helpers/isMobile'
import GridLogo from '../components/GridLogo/GridLogo'

// markup
const AboutUs = () => {
  let image = '../images/about/about-bottom-mobile.jpg'

  if (!isMobile()) {
    image = '../images/about/about-bottom.jpg'
  }

  return (
    <Layout className='about'>
      <Helmet>
        <title>About us | Kready</title>
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
          rel='stylesheet'
          type='text/css'
        />
        <link
          href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
          rel='stylesheet'
          type='text/css'
        />
      </Helmet>
      <MediaBox
        image='/images/about/about-top.jpg'
        title={text.title}
      ></MediaBox>
      <TextRow title={text.row.title} body={text.row.body} button={text.row.button} />
      {/* <GridBox>
        <Box color='grey' title={'Un progetto dalle solide radici'}></Box>
        <Box color='primary' title={faker.lorem.words()}></Box>
        <Box color='grey' title={faker.lorem.words()}></Box>
        <Box>{faker.lorem.paragraph()}</Box>
      </GridBox>
      <BoxFloatingVideo video='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'></BoxFloatingVideo> */}
      {/* <ParallaxMedia image={image}></ParallaxMedia> */}
      <GridLogo></GridLogo>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default AboutUs
